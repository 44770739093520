<template>
  <div style="background: #f6f6f7">
    <pcHeaders></pcHeaders>
    <div style="display: flex; justify-content: center; background: #fff; width: 100%; height: 100px; align-items: center">
      <img style="width: 1000px; height: 80px"
           src="../../assets/images/lc04.png"
           alt="" />
    </div>
    <div class="flex"
         style="justify-content: center">
      <div class="bannerrrr">
        <div class="flex"
             style="font-size: 16px; justify-content: space-between; margin-top: 30px">
          <div class="flex">
            <div>
              <img style="margin-top: 2px"
                   src="../../assets/images/02_03.png"
                   alt="" />
            </div>
            <div style="margin-left: 5px; font-size: 18px">核对订单信息</div>
          </div>
        </div>

        <div style="margin-top: 25px">
          <el-table :data="calssInfo.classList"
                    style="width: 100%">
            <el-table-column prop="className"
                             label="班级名称"
                             width="580"
                             align="center"> </el-table-column>

            <el-table-column prop="zyName"
                             label="专业分类"
                             width="380"
                             align="center"> </el-table-column>
            <el-table-column prop="credit"
                             label="学时"
                             align="center">
              <template slot-scope="scope">
                <span>{{ scope.row.credit.toFixed(1) }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="price"
                             label="价格"
                             align="center"> </el-table-column>
          </el-table>

          <div style="
              margin-top: 15px;
              display: flex;
              justify-content: space-between;
              border-bottom: 1px dotted #ccc;
              font-size: 18px;
              background: #fff;
              height: 80px;
              line-height: 80px;
              padding-right: 15px;
            ">
            <div></div>
            <div>
              订单金额：<span style="color: #ef1300; font-size: 22px"><span style="font-size: 16px">￥</span>{{ calssInfo.totalPrice }}</span>
            </div>
          </div>

          <div style="
              display: flex;
              justify-content: space-between;
              border-bottom: 1px dotted #ccc;
              font-size: 18px;
              background: #fff;
              height: 90px;
              padding-right: 15px;
              padding-top: 20px;
            ">
            <div></div>
            <div>
              <div v-if="showSelet">
                <el-checkbox v-model="checked"
                             @change="handlZh">
                  <div>
                    账户金额抵扣：<span style="color: #ef1300; font-size: 22px"><span style="font-size: 16px">￥</span>{{ calssInfo.totalPrice }}</span>
                  </div>
                </el-checkbox>
              </div>
              <div v-else>
                <div>
                  账户金额抵扣：<span style="color: #ef1300; font-size: 22px"><span style="font-size: 16px">￥</span>0</span>
                </div>
              </div>
              <div style="font-size: 14px; color: #797979; height: 50px; text-align: right">剩余：{{ deductionNum.toFixed(1) }}</div>
            </div>
          </div>

          <div style="
              display: flex;
              justify-content: space-between;
              border-bottom: 1px dotted #ccc;
              font-size: 18px;
              background: #fff;
              height: 80px;
              line-height: 80px;
              padding-right: 15px;
            ">
            <div></div>
            <div>
              应付金额：<span style="color: #ef1300; font-size: 22px"><span style="font-size: 16px">￥</span>{{ paymentNum }}</span>
            </div>
          </div>

          <div style="
              display: flex;
              justify-content: space-between;
              border-bottom: 1px dotted #ccc;
              font-size: 18px;
              background: #fff;
              height: 80px;
              line-height: 80px;
              padding-right: 15px;
              padding-top: 15px;
            ">
            <div></div>
            <div class="payBtn"
                 @click="purchase">提交支付</div>
          </div>
        </div>
      </div>
    </div>

    <pcBottom></pcBottom>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import pcHeaders from '../component/head.vue';
import pcBottom from '../component/bottom.vue';

import { toOrderConfirm, toGenerateOrder } from '@/api/pay.js';

export default {
  // import引入的组件需要注入到对象中才能使用
  components: {
    pcHeaders,
    pcBottom
  },
  data() {
    // 这里存放数据
    return {
      calssInfo: {}, //订单核对数据
      checked: false,
      deductionNum: 0,
      paymentNum: 0, //实付金额
      showSelet: false, //是否显示账户余额
      falg: true //节流
    };
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    purchase() {
      let data = {
        classIds: this.$route.query.classId
      };
      if (this.checked) {
        //账户方式
        data.derateType = '01';
      } else {
        data.derateType = '02';
      }
      //如果订单金额是0
      if (this.calssInfo.totalPrice == 0) {
        //账户方式
        data.derateType = '01';
      }
      if (this.falg) {
        this.falg = false
        toGenerateOrder(data).then(res => {
          console.log(res.data);
          this.falg = true
          if (res.data.data.code == '1') {
            this.$router.push({
              path: '/toPayOrder',
              query: {
                orderId: res.data.data.orderId
              }
            });
          } else if (res.data.data.code == '4') {
            this.$router.push({
              path: '/paySuccess',
              query: {
                orderId: res.data.data.orderId
              }
            });
          } else if (res.data.data.code == '3') {
            this.$message(res.data.data.msg);
            return;
          }
        });
      }
    },
    handlZh() {
      console.log(this.checked);
      if (this.checked) {
        //如果账户余额大于或等于实付金额
        if (this.calssInfo.balance >= this.calssInfo.totalPrice) {
          this.deductionNum = this.calssInfo.balance - this.calssInfo.totalPrice;
          this.paymentNum = 0;
        } else if (this.calssInfo.balance < this.calssInfo.totalPrice) {
          //如果账户余额小于实付金额
          this.deductionNum = 0;
          this.paymentNum = this.calssInfo.totalPrice - this.calssInfo.balance;
        } else if (this.calssInfo.balance == 0) {
          //没有账户余额
          this.paymentNum = this.calssInfo.totalPrice;
        }
      } else {
        this.deductionNum = this.calssInfo.balance;
        this.paymentNum = this.calssInfo.totalPrice;
      }
    },
    toOrderConfirm() {
      toOrderConfirm({ classIds: this.$route.query.classId }).then(res => {
        console.log(res.data);
        if (res.data.data.code == '1') {
          this.calssInfo = res.data.data.data;
          this.deductionNum = this.calssInfo.balance;
          this.paymentNum = this.calssInfo.totalPrice;
          if (this.deductionNum !== 0 && this.paymentNum !== null) {
            this.showSelet = true;
          } else {
            this.showSelet = false;
          }
        } else {
          this.$message(res.data.data.msg);
          this.$router.back();
        }
      });
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.toOrderConfirm();
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {} // 生命周期 - 挂载之前
};
</script>
<style lang="less" scoped>
/* //@import url(); 引入公共css类 */
.flex {
  display: flex;
}
.bannerrrr {
  background: #f6f6f7;
  padding: 20px 0;
  width: 1200px;
  font-family: Regular;
  .goBtn {
    width: 160px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #fff;
    background: #ff9f53;
    font-size: 15px;
    cursor: pointer;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
  }
  .payBtn {
    width: 121px;
    height: 32px;
    cursor: pointer;
    text-align: center;
    line-height: 32px;
    background: #fd9d57;
    color: #fff;
  }
}
</style>
